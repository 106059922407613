<template>
  <v-data-table
    :headers="headers.map(header => ({...header, text: $t(header.text)}))"
    :items="data"
    :loading="loading"
    class="elevation-2"
  />
</template>

<script>
import axios from 'axios'
import notification from "@/notification/notify";

export default {
  name: 'InventoryStep',
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: 'system.name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'system.quantity',
        sortable: true,
        value: 'quantity',
      }
    ],
    data: [],
    loading: false
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/inventory`)
        this.data = data.data.map(({product_name, quantity}) => ({name: product_name, quantity}))
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
